import React from "react"
import { useState } from 'react'
import { FileUploader } from "react-drag-drop-files"
import {NotificationContainer, NotificationManager} from 'react-notifications'
const fileTypes = ["JSON"];

const Cell = ({value, onChange, width='40px', inputWidth='20px'}) => {
    const itemStyle = {
        width:width,
        padding: '2px',
        border: '1px solid silver',
        fontFamily: 'Arial',
        fontSize: '10px',
        display: 'inline-flex'
    };
    
    const textStyle = {
        ...itemStyle,
        padding: '5px 4px',
    };
    
    const inputStyle = {
        display:"flex-inline",
        width:inputWidth,
        height:"22px",
        padding: '0',
        margin: '0',
        flex: '1',
        fontFamily: 'Arial',
        fontSize: '10px'
    };
    
    const buttonStyle = {
        flex: 'none',
        fontSize:"9px",
        width:"18px",
        height:"22px"
    };
    const [mode, setMode] = React.useState('read');
    const [text, setText] = React.useState(value ?? '');
  React.useEffect(() => {
      setText(value);
    }, [value]); // <--- when value is changed text state is changed too
  if (mode === 'edit') {
      const handleInputChange = (e) => {
          setText(e.target.value);
      };
      const handleSaveClick = () => {
          setMode('read');
            if (onChange) {
                onChange(text);
          }
      };
      const handleEnter = (e) => {
        if(e.key === 'Enter')
        handleSaveClick()
      };
      return (
        <div style={itemStyle}>
          <input type="text" value={text}
                 style={inputStyle} onChange={handleInputChange} onKeyDown={handleEnter} />
          <button style={buttonStyle} onClick={handleSaveClick}>OK</button>
        </div>
      );
  }
    if (mode === 'read') {
      const handleEditClick = () => {
          setMode('edit');
      };
      return (
        <div style={textStyle} onClick={handleEditClick}>{text}</div>
      );
  }
    return null;
};

function Dataverify()
{
    const [database, setDatabase] = useState([]);
    const [switchMesi, setSwitchMesi] = useState([]);
    const [switchCorsi, setSwitchCorsi] = useState([]);
    var readJson=(file)=>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
            fetch(reader.result).then(response=>{
                response.json().then(json=>{
                    setDatabase(json)
                    var switchers=[]
                    json.forEach((value,index)=>{
                        switchers[index]=false
                    })
                    setSwitchMesi(switchers)
                    setSwitchCorsi(switchers)
                })
            })
        }
    }
    var scaricaDatabase=()=>{
        if(database!==null&&database.length<=0)
        NotificationManager.warning("Nessun Database selezionato oppure è vuoto")
        else{
            var a = document.createElement("a");
            var file = new Blob([JSON.stringify(database)], {type: 'json'});
            a.href = URL.createObjectURL(file);
            a.download = (new Date()).getTime()+'_database.json';
            a.click();
            NotificationManager.success("Database scaricato con successo!")
        }
    }

    var getTotaleOre=(giorni_lavorati,totaleOreDaCampo)=>{
        var sum=0.0;
        giorni_lavorati.forEach(
          d=>{
            const ore=typeof d.ore!=="undefined"?d.ore.replace(/[A-z]/g,"").replace(",","."):0.0
            if(ore!=="")
            sum+=parseFloat(ore)
          });
          
        return {totaleOreDaCampo:totaleOreDaCampo,totaleore:sum.toFixed(2).replace('.',',')}
    }
    /*var handlerChange=(data)=>{
        setDatabaseTemp(data.jsObject)
    }*/

    var cambiaValore=(index,campo,value)=>{        
        database[index][campo]=value
    }
    
    var cancellaCorso=(index,indexCorso,e)=>{
        e.preventDefault()
        var prevDatabase=JSON.parse(JSON.stringify(database))
        prevDatabase[index].corsi.splice(indexCorso,1)
    
        setDatabase(prevDatabase)
    }
    var cancellaGiornoNelMese=(index,indexMese,indexM,e)=>{
        e.preventDefault()                
        const strMese=Object.keys(database[index].mesi)[indexMese]
        const prevDatabase=JSON.parse(JSON.stringify(database))
        const prevLastItem=prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1];
        prevDatabase[index].mesi[strMese].splice(indexM,1)
        prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1]=getTotaleOre(prevDatabase[index].mesi[strMese],prevLastItem.totaleOreDaCampo)
      
        setDatabase(prevDatabase)
    }

    var aggiungiGiornoNelMese=(index,indexMese,e)=>{
        e.preventDefault()
        const strMese=Object.keys(database[index].mesi)[indexMese]
        var prevDatabase=JSON.parse(JSON.stringify(database))

        if(prevDatabase[index].mesi[strMese]===null)
        prevDatabase[index].mesi[strMese]=[]

        if(prevDatabase[index].mesi[strMese].length>0)
        {
            const prevLastItem=prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1];       
            prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1]=getTotaleOre(prevDatabase[index].mesi[strMese],prevLastItem.totaleOreDaCampo)
        }else prevDatabase[index].mesi[strMese].push(getTotaleOre(prevDatabase[index].mesi[strMese],null))
        
        prevDatabase[index].mesi[strMese].splice(prevDatabase[index].mesi[strMese].length-1, 0, {giorno:"0",ore:"0"});
        setDatabase(prevDatabase)
    }
    var cancellaGiorni=(index,indexMese,e)=>{
        e.preventDefault()
        const strMese=Object.keys(database[index].mesi)[indexMese]
        var prevDatabase=JSON.parse(JSON.stringify(database))

        if(prevDatabase[index].mesi[strMese]===null)
        prevDatabase[index].mesi[strMese]=[]

        if(prevDatabase[index].mesi[strMese].length>0)
        {
            const prevLastItem=prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1];   
            prevDatabase[index].mesi[strMese].splice(0,prevDatabase[index].mesi[strMese].length-1)
            prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1]=getTotaleOre(prevDatabase[index].mesi[strMese],prevLastItem.totaleOreDaCampo)
        }
        
        setDatabase(prevDatabase)
    }
    var modificaGiornoDelMese=(index,indexMese,indexM,value)=>{
        const strMese=Object.keys(database[index].mesi)[indexMese]
        var prevDatabase=JSON.parse(JSON.stringify(database))
        
        const prevLastItem=prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1];  
        prevDatabase[index].mesi[strMese][indexM].giorno=parseInt(value)
        prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1]=getTotaleOre(prevDatabase[index].mesi[strMese],prevLastItem.totaleOreDaCampo)
        setDatabase(prevDatabase)
    }
    
    var modificaOreDelMese=(index,indexMese,indexM,value)=>{
        const strMese=Object.keys(database[index].mesi)[indexMese]
        var prevDatabase=JSON.parse(JSON.stringify(database))
        
        const prevLastItem=prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1];  
        prevDatabase[index].mesi[strMese][indexM].ore=value
        prevDatabase[index].mesi[strMese][prevDatabase[index].mesi[strMese].length-1]=getTotaleOre(prevDatabase[index].mesi[strMese],prevLastItem.totaleOreDaCampo)
        setDatabase(prevDatabase)
    }
    
    var handleSwitchMesi=(index)=>{
        setSwitchMesi((prevState)=>{
            const newState={ ...prevState }
            newState[index]=!prevState[index]
            return newState
        })
    }
    var handleSwitchCorsi=(index)=>{
        setSwitchCorsi((prevState)=>{
            const newState={ ...prevState }
            newState[index]=!prevState[index]
            return newState
        })
    }   

    var AggiungiCorso=(index)=>{
        var prevDatabase=JSON.parse(JSON.stringify(database))
        if(prevDatabase[index].corsi===null||typeof prevDatabase[index].corsi==='undefined')
            {prevDatabase[index].corsi=[]}

            prevDatabase[index].corsi.push("Nuovo Corso")

        setDatabase(prevDatabase)
    }

    var EliminaCorsista=(index)=>{
        var prevDatabase=JSON.parse(JSON.stringify(database))
        prevDatabase.splice(index,1)

        setDatabase(prevDatabase)
    }

    var getMonthTable=(index)=>{
        const corsista=database[index]
        return (switchMesi[index]===true)?             
        <table style={{textAlign:"center"}}>
            <thead>
                <tr style={{fontWeight:"bold"}}>
                    {Object.keys(corsista.mesi).map(
                        mese=>
                        {
                            const obj=corsista.mesi[mese]!==null?corsista.mesi[mese][corsista.mesi[mese].length-1]:null;
                            const totaleOreDaCampo=obj!==null?(obj.totaleOreDaCampo!==null?parseFloat(obj.totaleOreDaCampo).toFixed(2).replace(".",","):null):null
                            const totaleore=obj!==null?"("+obj.totaleore+")\n":""
                            return(<><td style={{padding:"0px 5px",margin:"0",color:(totaleore.replace(/\(|\)|\n/g,"")!==totaleOreDaCampo&&totaleOreDaCampo!=="NaN")?"red":"black"}}>{mese}<br/>{(corsista.mesi[mese]!==null)?totaleore+((totaleore.replace(/\(|\)|\n/g,"")!==totaleOreDaCampo&&totaleOreDaCampo!=="NaN")?totaleOreDaCampo:""):""}</td>
                            
                            </>)
                        }
                    )}                                                   
                </tr>
            </thead>
            <tbody>  
                <tr>
                {Object.values(corsista.mesi).map(
                        (mese,indexMese)=>{
                        if(mese!==null)
                        return (
                        <td style={{verticalAlign:"top",padding:"0px 3px",margin:"0"}}>
                            <button style={{fontSize:"11px",width:"70px"}} onClick={(e)=>cancellaGiorni(index,indexMese,e) }>Cancella Tutto</button>
                            {Object.values(mese).map(
                                (element,indexM)=>
                                <>
                                {
                                    (indexM < mese.length-1) ?
                                    <div style={{marginTop:"10px",display:"flex"}} key={indexM} defaultValue={indexM}>
                                    <Cell onChange={(e)=>modificaGiornoDelMese(index,indexMese,indexM,e)} value={element.giorno} /> : <Cell onChange={(e)=>modificaOreDelMese(index,indexMese,indexM,e)} value={element.ore} />
                                    <button onClick={(e)=>cancellaGiornoNelMese(index,indexMese,indexM,e)} style={{background:"orange",cursor:"pointer",padding:"3px",marginRight:"2px",border:"0",color:"white"}}>&times;</button>
                                    </div>
                                    :""
                                }                                
                                </>
                            )}                           
                            <button style={{fontSize:"11px",width:"70px"}} onClick={(e)=>aggiungiGiornoNelMese(index,indexMese,e) }>Aggiungi Giorno</button>
                        </td>
                        )
                        else return (<td><button onClick={(e)=>aggiungiGiornoNelMese(index,indexMese,e)} style={{width:"70px"}}>Aggiungi Giorno</button></td>)
                        }
                    )}   
                </tr>                                  
            </tbody>
        </table>:""
    }   
    
    return (<div>
                <NotificationContainer/>
                <h1>Verifica Dati</h1>
                <hr />
                <div>
                    <div className='block-inline'>
                    <FileUploader hoverTitle="Trascina qui" label="Clicca o Trascina qui il database in:" handleChange={readJson} name="file" types={fileTypes} />
                    </div>
                    <div className='block-inline'>
                    <button className='btn' onClick={scaricaDatabase}>Scarica Database</button>
                    </div>
                </div>
                <table style={{display:database.length>0?"block":"none"}}>
                    <thead>
                        <tr style={{fontWeight:"bold"}}>
                            <td>COGNOME</td>
                            <td>NOME</td>
                            <td>CODICE FISCALE</td>
                            <td>ANNO CORSI</td>
                            <td>MANSIONE</td>
                            <td>MESI</td>
                            <td>CORSI</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                    {database.map((corsista,index)=>  
                    <>                  
                        <tr>
                            <td><Cell width="130px" inputWidth="105px" onChange={(value)=>cambiaValore(index,"cognome",value)} value={corsista.cognome} /></td>
                            <td><Cell width="130px" inputWidth="105px" onChange={(value)=>cambiaValore(index,"nome",value)} value={corsista.nome} /></td>
                            <td><Cell width="130px" inputWidth="105px" onChange={(value)=>cambiaValore(index,"codicefiscale",value)} value={corsista.codicefiscale} /></td>
                            <td><Cell width="130px" inputWidth="105px" onChange={(value)=>cambiaValore(index,"anno",value)} value={corsista.anno} /></td>
                            <td><Cell width="130px" inputWidth="105px" onChange={(value)=>cambiaValore(index,"mansione",value)} value={corsista.mansione} /></td>
                            <td><button onClick={()=>handleSwitchMesi(index)}>{switchMesi[index]===false?"MOSTRA":"NASCONDI"}</button></td>
                            <td>
                                <button onClick={()=>handleSwitchCorsi(index)}>{switchCorsi[index]===false?"MOSTRA":"NASCONDI"}</button>
                                {switchCorsi[index]!==false?<div>{(corsista.corsi!==null&&typeof corsista.corsi!=='undefined')?corsista.corsi.map(
                                    (corso,indexCorso)=>
                                    <div style={{height:"25px",marginTop:"5px"}}>
                                        <Cell value={corso} width="130px" inputWidth="100px" onChange={(v)=>{var prevDatabase=JSON.parse(JSON.stringify(database)); prevDatabase[index].corsi[indexCorso]=v; setDatabase(prevDatabase);}} />
                                        <button onClick={(e)=>cancellaCorso(index,indexCorso,e)} style={{background:"orange",cursor:"pointer",padding:"3px",marginRight:"2px",border:"0",color:"white"}}>&times;</button>
                                    </div>                                 
                                ):""}
                                <button onClick={()=>AggiungiCorso(index)}>Aggiungi Corso</button>
                                </div>:""}
                            </td>
                            <td>
                                <button style={{cursor:"pointer",border:"0",background:"red",color:"white",padding:"2px 3px",borderRadius:"5px"}} onClick={()=>EliminaCorsista(index)}>Elimina</button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4}>{getMonthTable(index)}</td>
                        </tr>
                    </>
                    )}
                    </tbody>
                </table>                                       
            </div>)
}

export default Dataverify;