import { useState } from 'react'
import * as pdfjs from "pdfjs-dist"
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"
import { FileUploader } from "react-drag-drop-files"
import {NotificationContainer, NotificationManager} from 'react-notifications'
import * as XLSX from 'xlsx';

const fileTypes = ["PDF"];

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


function Pdftoexcel() {

var currentPage=1
const [pdfScreenScale, setPdfScreenScale] = useState(1.5);
const [pageBrowser, setPageBrowser] = useState({display:'none'});
const [pdfItems, setPDFItems] = useState();
const [pdfPage, setPDFPage] = useState(1);
const [pdfFile, setPDFFile] = useState();
const [selectedFile, setSelectedFile] = useState();
const [isFileSelected, setIsSelected] = useState(false);

var readPDFfromFile=(file)=>{
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () =>{
    setSelectedFile(reader.result)
    setIsSelected(true)
  }
}

const pdfScreenWidth=600
const pdfScreenHeight=900

var renderPage=(page)=>{
  

  
  const viewport = page.getViewport(pdfScreenScale);
        viewport.rotation=90
        viewport.scale=pdfScreenScale;
        viewport.width=pdfScreenWidth*pdfScreenScale;
        viewport.height=pdfScreenHeight*pdfScreenScale;
        viewport.transform = [pdfScreenScale, 0, 0, -pdfScreenScale, 0, pdfScreenWidth*pdfScreenScale]    

  const canvas = document.getElementById('pdf');
        canvas.height = viewport.width;
        canvas.width = viewport.height;  
                
        
        
  const ctx = canvas.getContext('2d')
        ctx.strokeStyle = "#FF0000"
        ctx.lineWidth = 1

  const renderContext = {
        canvasContext: ctx,
        viewport: viewport
        };   
        page.render(renderContext)
      }
var findRowItem=(row,x)=>{
  var thereIs=-1
row.forEach((element,index)=>{
  if(typeof element==="object")
  {
    if(element.x===x)
    thereIs=index
  }
})
return thereIs
}
var elaboraExcel=async ()=>{
  if(selectedFile){
  const excel=[]
    
  const pdf = pdfjs.getDocument(selectedFile)
  pdf.promise.then(function(pdf){
    for (let currentPage=1;currentPage<=pdf.numPages;currentPage++)
    {
      pdf.getPage(currentPage).then(function(page) {
        const items=[]
        const headers=[]
        page.getTextContent().then(function(textContent) {          
          var lastRange=0
          const row=[]
          for(let j = textContent.items.length-1; j >=0 ; j--) {
            const item=textContent.items[j]
            const y=item.transform[5]
            const x=item.transform[4]
            
            

            if(lastRange===0)lastRange=y

            if(item.str.trim()!=='')
            {
              if(headers.indexOf(x)===-1)
              headers.push(x)

              row.push({x:x,str:item.str})
            
              const currentRange=y-lastRange

              if(currentRange<-1||currentRange>1)
              {
                if(row.length>0)
                {
                  /*const newRow=[]
                  headers.forEach(h=>{
                    JSON.parse(JSON.stringify(row)).forEach(it=>{
                      const range=h-it.x
                      if(range>-2&&range<2)
                      {
                        if(headers.indexOf(it.x)===-1)
                        {
                          headers.push(it.x)
                          newRow.push(it)
                        }
                      }
                    })
                  })   */               
                  items.push(JSON.parse(JSON.stringify(row)))

                  row.splice(0, row.length)
                }                
                lastRange=y
              }
            }
          }
          
          headers.sort((a,b)=>a-b).forEach(xPos=>{                             
            items.forEach((r,index)=>{
              if(findRowItem(r,xPos)===-1)
              r.push({x:xPos,str:""}) 
              items[index]=r.sort((a,b)=>{return a.x-b.x})
            })
          })
          items.forEach((r,index)=>{
            var lastX=0
            r.forEach((cell,cellIndex)=>{
              const range=lastX-cell.x 
              //console.log(lastX,range,cell.str)     
              if(range<6 && range>-6)
              items[index][cellIndex]=cell.str
              
              lastX=cell.x
            })
          })
          //console.log(headers)
          excel.push(items)
          ///downloadExcel(items)
          
        })
        
      })
      }
    })
    setTimeout(()=>{        
      downloadExcel(excel)
    },5000)   
  }  
}

const downloadExcel=(excel)=>{
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const Sheets=[]
    const SheetNames=[]
    excel.forEach((s,sIndex)=>{
      const ws = XLSX.utils.json_to_sheet(s, {skipHeader:true});
      Sheets[sIndex.toString()]=ws
      SheetNames.push(sIndex.toString())
    })
    /*{ data: ws }*/
    const wb = { Sheets: Object.assign(Sheets) , SheetNames: SheetNames };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    var a = document.createElement("a");
    var file = new Blob([excelBuffer], {type: fileType});
    a.href = URL.createObjectURL(file);
    a.download = (new Date()).getTime()+'_pdftoexcel'+fileExtension;
    a.click();
}

var openFile=()=>{
  if(isFileSelected){
    const pdf = pdfjs.getDocument(selectedFile)
    var str=[];
    pdf.promise.then(function(pdf){
      setPDFFile(pdf)
      var pdf_canvas=[]; 
      pdf.getPage(currentPage).then(function(page) {
        renderPage(page)
        page.getTextContent().then(function(textContent) {
          for(let j = 0; j < textContent.items.length; j++) {
            const item=textContent.items[j];
            if(item.str.trim()!==null&&item.str.trim()!=='')
            {       
              const itemStyle={position:'absolute',
              zIndex:10,
              top:(item.transform[5]*-pdfScreenScale+pdfScreenWidth*pdfScreenScale)-(item.transform[0]>0?item.height:item.width)*pdfScreenScale,
              left:item.transform[4]*pdfScreenScale-(item.transform[0]>0?0:4*pdfScreenScale),
              width:(item.transform[0]>0?item.width:item.height)*pdfScreenScale,
              height:(item.transform[0]>0?item.height+1*pdfScreenScale:item.width)*pdfScreenScale,
              display:'block'}       
              pdf_canvas.push(<div className='strings' style={itemStyle}></div>)
              str.push(item);
            }
          }
          setPDFItems(pdf_canvas)  
        });
      });
    })
  }else NotificationManager.warning('Nessun file selezionato')
}

var nextPage=()=>{
  if(isFileSelected){
    if(pdfPage<pdfFile.numPages)
    {
      currentPage=pdfPage+1
      setPDFPage(currentPage)
      openFile()
    }
  }
}
var changePage=(e)=>{
  const page=parseInt(e.target.value)
  if(isFileSelected){
    if(page<pdfFile.numPages&&page>=1)
    {
      currentPage=page
      setPDFPage(currentPage)
      openFile()
    }
  }
}
var previousPage=()=>{  
  if(isFileSelected){    
    if(pdfPage>1)
    {
      currentPage=pdfPage-1
      setPDFPage(currentPage)      
      openFile()
    }
  }  
}
var resetPageBrowser=()=>{
  if(isFileSelected)
  setPageBrowser({display:'block'})
}
var zoomIn=()=>{
  if(isFileSelected)
  {
    setPdfScreenScale(parseFloat(((pdfScreenScale+0.1).toFixed(2))))
    setPDFPage(currentPage)
    setTimeout(()=>{openFile();resetPageBrowser();}, 500)    
  }
}
var zoomOut=()=>{
  if(isFileSelected)
  {
    setPdfScreenScale(parseFloat(((pdfScreenScale-0.1).toFixed(2))))
    setPDFPage(currentPage)
    setTimeout(()=>{openFile();resetPageBrowser();}, 500)    
  }
}


  return (
    <div>      
      <NotificationContainer/>
      <h1>PDF to Excel</h1> 
      <hr />
      <div>
        <div className='block-inline'>
          <FileUploader hoverTitle="Trascina qui" label="Clicca o Trascina qui un PDF" handleChange={readPDFfromFile} name="file" types={fileTypes} />
        </div>
        <div className='block-inline'>
          <button className='btn' onClick={()=>{ setPDFPage(1); setTimeout(()=>{openFile();}, 500);resetPageBrowser( )}} >Apri PDF</button>
          <button className='btn' onClick={elaboraExcel}>Salva Excel</button>
        </div>
        <div style={pageBrowser}>
          <button className='btn' onClick={previousPage} >Pecedente</button>    
          <input type="number" style={{height: "28px",textAlign: "center",fontSize: "16px",width: "44px"}} onChange={changePage} value={pdfPage} />
          <button className='btn' onClick={nextPage} >Prossima</button>
          <span> Zoom: </span>
          <button className='btn' onClick={zoomOut} >-</button> 
          <span>{pdfScreenScale}</span>   
          <button className='btn' onClick={zoomIn} >+</button>
        </div>
      </div>
      <div style={{position:'relative',width:pdfScreenWidth,height:pdfScreenHeight}}>
        {pdfItems}
        <canvas style={{position:'absolute',zIndex:1}} id="pdf"></canvas>
      </div>
    </div>
  );
}

export default Pdftoexcel;