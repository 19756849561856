import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './sidebar.scss';

const sidebarNavItems = [
    {
        display: 'Dashboard',
        icon: <i className='bx bx-home'></i>,
        to: '/',
        section: ''
    },
    {
        display: 'Leggi BP PDF',
        icon: <i className='bx bx-file-find'></i>,
        to: '/leggibustepaghe',
        section: 'leggibustepaghe'
    },
    {
        display: 'Verifica Dati',
        icon: <i className='bx bx-check-double'></i>,
        to: '/verificadati',
        section: 'verificadati'
    },
    {
        display: 'Crea Documenti',
        icon: <i className='bx bx-file'></i>,
        to: '/creadocumenti',
        section: 'creadocumenti'
    }
    /*,  
    {
          display: 'PDF to Excel',
          icon: <i className='bx bx-file-blank'></i>,
          to: '/pdftoexcel',
          section: 'pdftoexcel'
    }
    */
]

const Sidebar = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepHeight, setStepHeight] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
            indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
            setStepHeight(sidebarItem.clientHeight);
        }, 50);
    }, []);

    // change active index
    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1];
        const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return <div className='sidebar'>
        <div className="sidebar__logo" style={{fontSize:'20px'}}>
            <img style={{width: '66px'}} src="./logo192.png" alt='Logo PDF Data Entring' /> PDF Data Entring
        </div>
        <div ref={sidebarRef} className="sidebar__menu">
            <div
                ref={indicatorRef}
                className="sidebar__menu__indicator"
                style={{
                    transform: `translateX(-50%) translateY(${activeIndex * stepHeight}px)`
                }}
            ></div>
            {
                sidebarNavItems.map((item, index) => (
                    <Link to={item.to} key={index}>
                        <div className={`sidebar__menu__item ${activeIndex === index ? 'active' : ''}`}>
                            <div className="sidebar__menu__item__icon">
                                {item.icon}
                            </div>
                            <div className="sidebar__menu__item__text">
                                {item.display}
                            </div>
                        </div>
                    </Link>
                ))
            }
        </div>
    </div>;
};

export default Sidebar;
