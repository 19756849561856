import Holidays from "date-holidays";

const consonanti=['B','C','D','F','G','H','J','K','L','M','N','P','Q','R','S','T','V','W','X','Z','b','c','d','f','g','h','j','k','l','m','n','p','q','r','s','t','v','w','x','z',"'"];
const vocali=['A','E','I','O','U','Y','a','e','i','o','u','y',"'"];

var canshowSchemaList='none';
var SwitchCanShowSchemaList = ()=>{
  if(canshowSchemaList==='block')
  canshowSchemaList='none'
  else   
  canshowSchemaList='block'
}

const elimina_vocali=(nominativo)=>{
  var regexVocali = new RegExp(vocali.join("|"), 'gi');
  return nominativo.replace(regexVocali,"");
}
const elimina_consonanti=(nominativo)=>{
  var regexVocali = new RegExp(consonanti.join("|"), 'gi');
  return nominativo.replace(regexVocali,"");
}
const unisciArray = (nominativo,start=0)=>{
  var end = nominativo.length - 1;
  end++;
  return nominativo.slice(start,end).join('');
}

const separa_da_cognome=(nominativo,codicefiscale)=>{
  if(nominativo!==null&&nominativo.includes(" ")&&codicefiscale!==null){
    nominativo=nominativo.match(/[0-9]/g)!==null?nominativo.replace(/[0-9]/g,"").trim():nominativo    
    var arrayNominativo=nominativo.split(' ')
    var cN=codicefiscale.substring(3,6)
    var cC=codicefiscale.substring(0,3)
    var nome='';
    var cognome='';
    var fC=0;
    var cognomeCompletato=false;
    
    for(var i=0;i<arrayNominativo.length;i++) {
      var n=arrayNominativo[i]
      var nsv=elimina_vocali(unisciArray(arrayNominativo,i))
      var nsc=elimina_consonanti(n)
      var nsvMaybe=nsv.length<=3?(nsv.length<3?nsv+nsc.slice(0,3-nsv.length):nsv):nsv.slice(0, 1)+nsv.slice(2, 4)
      //console.log(nsvMaybe)
      if(nsv===cC || (i>=0 && cognomeCompletato===false && (nsv!==n&&nsvMaybe!==n)))
      {
        if(nsvMaybe===cN||nsv===cN)
        {
          cognomeCompletato=true
          fC=i
        }
        else
        {
          cognome+=(i===0?"":" ")+n
        }
      }

      if(cognomeCompletato===true)
      nome+=(i===fC?"":" ")+n
    }
    return {nome:nome,cognome:cognome,iscomplete:(nome!==null&&nome!==""&cognome!==null&&cognome!=="")?true:false}
  }
  return {nome:null,cognome:null}
}

const separa_da_nome=(nominativo,codicefiscale)=>{
  if(nominativo!==null&&nominativo.includes(" ")&&codicefiscale!==null){
    nominativo=nominativo.match(/[0-9]/g)!==null?nominativo.replace(/[0-9]/g,"").trim():nominativo
    var arrayNominativo=nominativo.split(' ')
    var cN=codicefiscale.substring(3,6)
    var cC=codicefiscale.substring(0,3)
    var nome='';
    var cognome='';
    var fC=0;
    var nomeCompletato=false;
    for(var i=0;i<arrayNominativo.length;i++) {
      var n=arrayNominativo[i]
      var nsv=elimina_vocali(unisciArray(arrayNominativo,i))
      nsv=nsv.length>3?nsv.substring(0,3):nsv
      var nsc=elimina_consonanti(n)
      var nsvMaybe=nsv.length<=3?(nsv.length<3?nsv+nsc.slice(0,3-nsv.length):nsv):nsv.slice(0, 1)+nsv.slice(2, 4)
      //console.log(nsvMaybe)
      if(nsv===cN || (i>=0 && nomeCompletato===false && (nsv!==n&&nsvMaybe!==n)))
      {        
        if(nsvMaybe===cN||nsv===cC)
        {
          nomeCompletato=true
          fC=i
        }
        else
        {          
          nome+=(i===0?"":" ")+n
        }
      }

      if(nomeCompletato===true)
      cognome+=(i===fC?"":" ")+n
    }
    return {nome:nome,cognome:cognome,iscomplete:(nome!==null&&nome!==""&cognome!==null&&cognome!=="")?true:false}
  }
  return {nome:null,cognome:null}
}


var SET='set';
var GET='get';

var schema={codicefiscale:null,nome:null,cognome:null,mansione:null,mese:null,anno:null/*,totaleOreDC:null,datainizioL:null,datafineL:null*/,costoOrario:null}
var schemaEmpty={codicefiscale:null,nome:null,cognome:null,mansione:null,mese:null,anno:null/*,totaleOreDC:null,datainizioL:null,datafineL:null*/,costoOrario:null}
function SetOrGetItem(action,item,val=''){

  var r=false;
  
  if(action===SET)
  {
    Object.keys(schema).forEach((key,value) => {
      if(item===key)
      schema[key]=val
    });
  }
  else if(action===GET)
  {
    Object.keys(schema).forEach((key,value) => {
      if(item===key)
      r=schema[key]
    });
  }
  return r;
}
function setSchemaforPage(key,str,doActionList:VoidFunction,e){
  schema[key]=str
  doActionList()  
  //console.log(schema)
}

function sList(str,doActionList:VoidFunction){
  var sList=[];
    Object.keys(schema).forEach((key) => {
          if(schema[key]===null)
            sList.push(<button onClick={setSchemaforPage.bind(this,key,str,doActionList)}>{key}</button>)        
        
      });
    return (<div style={{position:"absolute",right:"-150px",top:"21px",width:"150px",display:canshowSchemaList}}>{sList}</div>);
}
function monthToNumber(mese)
{
  var m=""
  const mesi={"01":"GENNAIO","02":"FEBBRAIO","03":"MARZO","04":"APRILE","05":"MAGGIO","06":"GIUGNO",
  "07":"LUGLIO","08":"AGOSTO","09":"SETTEMBRE","10":"OTTOBRE","11":"NOVEMBRE","12":"DICEMBRE"}
  Object.keys(mesi).forEach(key=>{
    if(mesi[key]===mese)
    m=key;
  })
  return m
}
function getFestivita(a){
  const anno=parseInt(a)
  var f=(new Holidays("IT")).getHolidays(anno)
  //console.log(f)
  var festivita=[]  
  f.forEach(d=>{
    const date=(d.date.split(" ")[0]).split("-")
    const mese=parseInt(date[1])-1
    const giorno=parseInt(date[2])
    festivita.push((new Date(anno,mese,giorno)).toLocaleDateString())
  })
  return festivita
}
function trovaAnno(testo)
{
  const regex = /[0-9]{4}/g;
  const found = testo.match(regex);
  return found!==null?parseInt(found[0]):((new Date()).getFullYear()-1)
}
function trovaMese(testo)
{
  const str=testo.toUpperCase();
  const mesi1=["GENNAIO","FEBBRAIO","MARZO","APRILE","MAGGIO","GIUGNO",
  "LUGLIO","AGOSTO","SETTEMBRE","OTTOBRE","NOVEMBRE","DICEMBRE"]
  const mesi2=["GEN","FEB","MAR","APR","MAG","GIU",
  "LUG","AGO","SET","OTT","NOV","DIC"]
  const regex1 =  new RegExp(mesi1.toString().replace(/,/g, '|'), 'gi');
  const regex2 =  new RegExp(mesi2.toString().replace(/,/g, '|'), 'gi');
  const found1 = str.match(regex1);
  const found2 = str.match(regex2);
  if(found2!==null&&found2.length>0)
  return mesi1[mesi2.indexOf(found2[0])]
  if(found1!==null&&found1.length>0)
  return found1[0]
  /*
  console.log(found1)
  console.log(found2)*/
}
//var giornoEsiste=(giorni_lavorati,giorno)=>{var exists=false; giorni_lavorati.forEach(d=>{if(giorno===d.giorno)exists=true}); return exists}
var getTotaleOre=(giorni_lavorati)=>{
  var sum=0.0;
  giorni_lavorati.forEach(
    d=>{
      const ore=typeof d.ore!=="undefined"?d.ore.replace(/[A-z]/g,"").replace(",","."):0.0
      if(ore!=="")
      sum+=parseFloat(ore)
    });
    return sum.toFixed(2).replace('.',',')
  }
const valoriUnici=(giorni_lavorati)=>{
  var gl=JSON.parse(JSON.stringify(giorni_lavorati))
  var valori_unici=[]
  var giorni=[]
  gl.forEach(
    d=>{
      if(giorni.findIndex(el=> el===d.giorno)===-1)
      {
          valori_unici.push(d)
          giorni.push(d.giorno)
      }
    });
    return JSON.parse(JSON.stringify(valori_unici))
  }
function getcompletedSchema(str){
  //console.log(str)
  const strSchema=str
  const anno=strSchema[0]?.anno
  var newSchema=[]
  const festivita=getFestivita(anno)
  //console.log(festivita)
  strSchema.forEach(element => {
    const mesi=element?.mesi
    if(element?.nome===null||element?.nome!==""||element?.cognome!==null||element?.cognome!==""||element?.nome.trim()===element?.cognome.trim())
    {
      //console.log({element:element,nome:element.nome,cognome:element.cognome})
      const nominativo=element?.cognome!==null?element?.cognome:element.nome
      try{
        const sc=separa_da_cognome(nominativo,element?.codicefiscale)
        const sn=separa_da_nome(nominativo,element?.codicefiscale)
        if(sc.iscomplete===true)
        {
          element.nome=sc.nome
          element.cognome=sc.cognome
        }
        else if(sn.iscomplete===true)
        {
          element.nome=sn.nome
          element.cognome=sn.cognome
        }
      }
      catch{
        
      }
    }
    if(typeof mesi === "object")
    Object.keys(mesi).forEach((mese,indexMese)=>{
      mese=trovaMese(mese)
      const numMese=parseInt(monthToNumber(mese.replace(/\s/g,"")))-1
      if(mesi[mese]!==null)
      {
        if(mesi[mese].hasOwnProperty('days'))
        {
          var ore_fatte=[]          
          var giorni_lavorati=[]
          const days=mesi[mese].days
          const hours=mesi[mese].hours
          const totaleOreDaCampo=mesi[mese].totaleOreDC!==null?mesi[mese].totaleOreDC.replace(/[A-z]|\s/g,""):""
        
          for(var i=0;i<days.length;i++){   
            const day=days[i]       
            const giorno=day.str.match(/[0-9]/g)!==null?day.str.match(/[0-9]/g).join(""):day.str
            
            for(var j=0;j<hours.length;j++){ 
              const hour=hours[j]
              const rangeY=day.itemY-hour.itemY
              const rangeX=day.itemX-hour.itemX
              if((rangeY>-rangeYforExtraction&&rangeY<rangeYforExtraction&&day.direction==="v"&&hour.direction==="v")||
              (rangeX>-4&&rangeX<4&&day.direction==="o"&&hour.direction==="o"))
              {
                const data=new Date(anno,numMese,parseInt(giorno.replace(/\s/g,"")))
  
                //console.log({giorno:giorno,str:day.str}) 

                if(ore_fatte.indexOf(hour.uuid)===-1&&giorno.replace(/\s/g,"")!==""&&!festivita.includes(data.toLocaleDateString())
                &&!(isNaN(parseInt(day.str.replace(/\s/g,"")))&&isNaN(parseInt(hour.str.replace(/\s/g,"")))))
                {
                  ore_fatte.push(hour.uuid)
                  var ore=hour.str.replace(/\s/g,"")
                  var oreMatch=ore.match(/[0-9]{4}/g)
                  if(oreMatch!==null)
                  {
                    var m=ore.match(/[0-9]{2}/g)
                    if(m!==null)
                    {
                      ore=parseInt(m[0])+","+parseInt(m[1])
                    }
                  }
                  giorni_lavorati.push({giorno:parseInt(giorno),ore:ore})
                }
              }
              else if((ore_fatte.indexOf(hour.uuid)===-1&&rangeY>hour.height/2&&rangeY<hour.height/2&&day.direction==="v"&&hour.direction==="v"&&hour.str.length>=3)||
              (ore_fatte.indexOf(hour.uuid)===-1&&rangeX>hour.width/-2&&rangeX<hour.width/2&&day.direction==="o"&&hour.direction==="o"&&hour.str.length>=3))
              {
                const giorno=parseInt((day.str.match(/[0-9]/g)!==null?day.str.match(/[0-9]/g).join(""):day.str).replace(/\s/g,""))
                console.log({other:"other",giorno:giorno,str:day.str}) 
       
                const data=new Date(anno,numMese,giorno)

                const hs=hour.str.split(/\s/g)
                const hn=hour.str.match(/[1-9]{1},[1-9]{2}|[1-9]{2},[1-9]{2}/g)
                const d={day:giorno}
                
                  const oreMatch=hour.str.match(/[0-9]{4}/g)
                  if(oreMatch!==null&&oreMatch.length>1)
                  {
                    oreMatch.forEach(ore=>{
                      ore_fatte.push(hour.uuid)
                      const m=ore.match(/[0-9]{2}/g)
                      if(m!==null)
                      {
                        ore=parseInt(m[0])+","+parseInt(m[1])
                      }
                      
                      if(!festivita.includes(data.toLocaleDateString()))                   
                      giorni_lavorati.push({giorno:d.day,ore:ore})
                      d.day++
                    })
                  }
                  else
                  {
                    if(hs.length>1)
                    {
                      hs.forEach(element => {    
                        if(ore_fatte.indexOf(hour.uuid)===-1)
                        ore_fatte.push(hour.uuid)            
                        
                        if(!festivita.includes(data.toLocaleDateString()))
                        giorni_lavorati.push({giorno:d.day,ore:element})
                        d.day++
                      });
                    }
                    if(hn!==null&&hn.length>1)
                    {
                      hn.forEach(element => {   
                        if(ore_fatte.indexOf(hour.uuid)===-1)
                        ore_fatte.push(hour.uuid)  
                        
                        if(!festivita.includes(data.toLocaleDateString()))
                        giorni_lavorati.push({giorno:d.day,ore:element})
                        d.day++
                      });
                    }
                }
              }
              else{
                //console.log({other:"other",str:day.str}) 
              }
            }    
          }
          //console.log(ore_fatte)
          /*const unique = [giorni_lavorati.filter((obj, index) => {
              return index === giorni_lavorati.findIndex(o => obj.giorno === o.giorno);
          })];*/
          const unico=valoriUnici(giorni_lavorati)
          const totaleore=getTotaleOre(unico)
          //console.log(unique,giorni_lavorati)
          unico.push({totaleOreDaCampo:totaleOreDaCampo,totaleore:totaleore})
          //console.log(unico)
          mesi[mese]=unico
        }
      }
    })
    
    newSchema.push(element)
  });
  
  const Database=[]
  newSchema.forEach((element,index)=>{
    const dbIndex=findIndexDatabase(Database,element?.codicefiscale)
    if(dbIndex===-1&&element?.codicefiscale!==null)
      Database.push(element)
    else{
      try{
      Object.keys(element.mesi).forEach(mese=>{
        if(element.mesi[mese]!==null&&Database[dbIndex].mesi[mese]!==null)
        {
          element.mesi[mese].forEach((day)=>{
            if(day.hasOwnProperty('totaleore')){
                const lastElement=Database[dbIndex].mesi[mese][Database[dbIndex].mesi[mese].length-1]
                lastElement.totaleore=(parseInt(lastElement.totaleore)+parseInt(day.totaleore)).toFixed(2).replace(".",",")
                
                if(day.hasOwnProperty('totaleOreDaCampo'))
                lastElement.totaleOreDaCampo=(parseInt(lastElement.totaleOreDaCampo)+parseInt(day.totaleOreDaCampo)).toFixed(2).replace(".",",")
                //Database[dbIndex].mesi[mese][Database[dbIndex].mesi[mese].length]=null
            }
            else
            Database[dbIndex].mesi[mese].splice(Database[dbIndex].mesi[mese].length-1,0,day)
          })
          
        }
        else if(element.mesi[mese]!==null){
          Database[dbIndex].mesi[mese]=element.mesi[mese]
        }
      })
    }catch{}
    }
  })
  
  //setTimeout(()=>{console.log({newSchema:newSchema,Database:Database})},500)

  return Database
}
var findIndexDatabase=(Database,codicefiscale)=>{
  var i=-1
  Database.forEach((element,index)=>{
    if(element?.codicefiscale===codicefiscale)
    i=index
  })
  return i
}
var rangeYforExtraction=2
var setRangeFE=(range)=>{
  rangeYforExtraction=range
}

export {separa_da_cognome,separa_da_nome,SetOrGetItem,SET,GET,schema,schemaEmpty,sList,SwitchCanShowSchemaList,canshowSchemaList,
  getcompletedSchema,trovaAnno,trovaMese,monthToNumber,getFestivita,getTotaleOre,findIndexDatabase,setRangeFE,rangeYforExtraction};
