import React from "react"
import { useState, useEffect } from 'react'
import { FileUploader } from "react-drag-drop-files"
import {NotificationContainer, NotificationManager} from 'react-notifications'
import * as XLSX from 'xlsx';

const fileTypes = ["JSON"];

/*function ExcelDateToJSDate(serial) {
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
 
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}
*/
function DocumentCreator()
{
    /*const [oraFineP, setOraFineP] = useState("18:00");
    const [oraInizioP, setOraInizioP] = useState("14:00");
    const [oraFineM, setOraFineM] = useState("13:00");
    const [oraInizioM, setOraInizioM] = useState("09:00");
    const [includiFestivi, setIncludiFestivi] = useState(false);
    const [totaleOreDaCampo, setTotaleOreDaCampo] = useState(false);
*/
    const [database, setDatabase] = useState([]);
    //const databaseStyle={display:"block",width:"100%",textAlign:"left"}

    const [checkedAll, setCheckedAll] = useState(false);
    const [peopleChecked, setPeopleChecked] = useState([]);


    /*const selectPeople=(inputName)=>{
        setPeopleChecked((prevState) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
          })
    }*/
    
    /*const selezionaTutti=(value)=>{
        setCheckedAll(value)
        setPeopleChecked((prevState) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
              newState[inputName] = value;
            }
            return newState;
          });
    }*/
    useEffect(() => {
        let allChecked = true;
        for (const inputName in peopleChecked) {
          if (peopleChecked[inputName] === false) {
            allChecked = false;
          }
        }
        if (allChecked) {
          setCheckedAll(true);
        } else {
          setCheckedAll(false);
        }
      }, [peopleChecked])

    /*const databaseItems=database.sort((a,b)=>{return (a.cognome!==null?a.cognome:"").toString().localeCompare(b.cognome!==null?b.cognome:"")}).map((element,index)=>                
    <label style={databaseStyle} htmlFor={element.codicefiscale}><input id={element.codicefiscale} type="checkbox" onChange={() => selectPeople(element.codicefiscale)} checked={peopleChecked[element.codicefiscale]} value={index} />{element.cognome} {element.nome}</label>
    )*/

    /*const getMonthFromString=(mese)=>{
        const arrayMesi=["GENNAIO","FEBBRAIO","MARZO","APRILE","MAGGIO","GIUGNO",
      "LUGLIO","AGOSTO","SETTEMBRE","OTTOBRE","NOVEMBRE","DICEMBRE"]
        return (arrayMesi.indexOf(mese)+1)
    }*/

    var readJson=(file)=>{
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>{
            fetch(reader.result).then(response=>{
                response.json().then(json=>{
                    setDatabase(json)
                    json.forEach(element=>{
                        setPeopleChecked((prevState) => {
                            const newState = { ...prevState };
                            newState[element.codicefiscale] = checkedAll;
                            return newState;
                          })
                    })
                })
            })
        }
    }

    /*var hoursToValue=(hoursStr)=>{
        var hours=hoursStr.split(":")
        var h=parseFloat(hours[0])
        var m=(parseFloat(hours[1])/60)*100
        return {h:h,m:m,sum:(h+m/100),str:hoursStr} 
    }
    var numToHours=(num)=>{
        var numbers=num.toString().split(".")
        var h=parseFloat(numbers[0])
        var mN=typeof numbers[1]==="undefined"?0:numbers[1].toString().length>1?parseFloat(numbers[1]):(parseFloat(numbers[1])<10?parseFloat(numbers[1])*10:parseFloat(numbers[1]))
        var m=Math.round(mN*0.6)
        //console.log(num,h,m)
        return [h<10?"0"+h:h,isNaN(m)?"00":(m<10?"0"+m:((m>99)?m.toString()[0]+m.toString()[1]:m))].join(":")
    }
    var getTotaleOreSenzaFestivi=(anno,mese,giorni)=>{
        var sum=0.0
        if(giorni!==null&&giorni.length>1)
        {
            //console.log(giorni)
            giorni.forEach(d=>{
                const mt=getMonthFromString(mese)
                const date=new Date(anno,mt-1,parseInt(d.giorno))
                const ore=typeof d.ore!=="undefined"?d.ore.replace(/[A-z]/g,"").replace(",","."):0.0
                if(date.getDay()>0&&ore!=="")
                {
                    //console.log(ore)
                    sum+=parseFloat(ore)
                }
                                     
            })
        }
        return sum
    }*/

    var creaReportBustePaghe=()=>{
      if(database!==null&&database.length<=0)
      NotificationManager.warning("Nessun Database selezionato oppure è vuoto")
      else{
        const wb = XLSX.utils.book_new();
        const wsname = "Registro";
        const header=["","DIPENDENTE/MESE","Attività Progetto",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,"","ORE LAVORATE","ORE PROGETTO","COSTO ORARIO","Costo progetto"]
     
        var dataArray=[]

        //lettura dei dati
        const arrayMesi=["GENNAIO","FEBBRAIO","MARZO","APRILE","MAGGIO","GIUGNO",
          "LUGLIO","AGOSTO","SETTEMBRE","OTTOBRE","NOVEMBRE","DICEMBRE"]
        database.sort((a,b)=>{return (a.cognome!==null?a.cognome:"").toString().localeCompare(b.cognome!==null?b.cognome:"")}).forEach(db=>{     
          dataArray.push([]); 
          dataArray.push(header)
          dataArray.push(["",db.cognome+" "+db.nome])      
          var fc=dataArray.length+1
        arrayMesi.forEach(mese=>{

         
            if(db.mesi[mese]!==null){
              const arrayRow=["",mese,""]  
              var sumHour=0
              for(var day=1;day<=31;day++){
                const indexOfMonth=db.mesi[mese].findIndex(x=>x.giorno === day);
                var giorno=+(+db.mesi[mese][indexOfMonth]?.ore.replace(",",".")) || "";
                arrayRow.push(giorno)
                sumHour+=+giorno
                /*if(indexOfMonth)
                  arrayRow.push(db.mesi[mese][indexOfMonth].ore || 0);
                else arrayRow.push("")  */
              }    
              arrayRow.push("")
              arrayRow.push({f:`SUM(D${dataArray.length+1}:AH${dataArray.length+1})`,w:sumHour})
              arrayRow.push({f:`AJ${dataArray.length+1}*0.6`,w:sumHour*0.6})
              arrayRow.push(parseFloat((db?.costoOrario || "0").replace(",",".")))
              arrayRow.push({f:`AK${dataArray.length+1}*AL${dataArray.length+1}`,w:parseFloat((db?.costoOrario || "0").replace(",","."))*(sumHour*0.6),z:{numFmt: "€#.###.00"}})
              dataArray.push(arrayRow)
            }
            else dataArray.push(["",mese,""])
          }) 
          dataArray.push(["","TOTALI","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",{f:`SUM(AJ${fc}:AJ${dataArray.length})`,w:``},{f:`SUM(AK${fc}:AK${dataArray.length})`,w:``},{f:`SUM(AL${fc}:AL${dataArray.length})`,w:``},{f:`SUM(AM${fc}:AM${dataArray.length})`,w:``}])
     
          dataArray.push([]); 
          dataArray.push([]); 
      })
      dataArray.forEach((row,index)=>
      {
          //console.log(row)
          if(row!==null&&row.length>0)
          {
              if(row[0]!==null)
              if(row[0].hasOwnProperty("w")&&row[0].w.trim()==="TOTALE")
              {
                  row.forEach((cell,cellIndex)=>
                  {
                      const letter=XLSX.utils.encode_col(cellIndex)
                      switch(letter)
                      {
                          case "X":
                              if(cell.hasOwnProperty('f'))
                              dataArray[index][cellIndex].f="U"+index+"*100/S"+index
                          break;
                          default:
                              if(cell.hasOwnProperty('f')&&cell.f.indexOf("SUM")>-1)
                              dataArray[index][cellIndex].f="SUM("+letter+4+":"+letter+(index-1)+")"
                          break;
                      }
                      //console.log(index, cell,cellIndex,XLSX.utils.encode_col(cellIndex),dataArray.length)
                  })
              }
          }
      })
      
        //creazione documento
        wb.Sheets[wsname]=XLSX.utils.json_to_sheet(dataArray, {skipHeader:false})
        const ws = XLSX.utils.aoa_to_sheet(dataArray);
        ws["!cols"] = [{wch:2},{wch:20},{wch:20},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:3},{wch:2},{wch:15},{wch:15},{wch:15},{wch:15}];
        XLSX.utils.book_append_sheet(wb, ws, wsname);
                
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        var a = document.createElement("a");
        var file = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        a.href = URL.createObjectURL(file);
        a.download = (new Date()).getTime()+'_proiezione.xlsx';
        a.click();
        NotificationManager.success("Proiezione Creata con successo!")
      }
    }

    /*var creaProiezione=()=>{
        if(database!==null&&database.length<=0)
        NotificationManager.warning("Nessun Database selezionato oppure è vuoto")
        else{
            fetch('./xlsx/proiezione.xlsx')
            .then(response => response.arrayBuffer())
            .then(text => {
                const wb =XLSX.read(text, { bookType: 'xlsx',type: 'binary' })
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                var range = XLSX.utils.decode_range(ws['!ref']);
                var dataArray=[]
                for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {  
                    var dataRow=[]            
                    for(let colNum=range.s.c; colNum <= range.e.c; colNum++)
                    {
                        const cell = ws[XLSX.utils.encode_cell({r: rowNum, c: colNum})];
                        dataRow.push(typeof cell==='undefined'?'':cell); 
                    }
                    dataArray.push(dataRow)                    
                }
                var defaultValues=(JSON.parse(JSON.stringify(dataArray[3])))
        
                dataArray.splice(3, 1) 
                var counter=database.length+4
                database.sort((a,b)=>{return (a.cognome!==null?a.cognome:"").toString().localeCompare(b.cognome!==null?b.cognome:"")}).forEach(db=>{           
                    counter--
                    const header=dataArray[2]                        
                    const arrayRow=(JSON.parse(JSON.stringify(defaultValues)))
                    //console.log(arrayRow)
                    Object.entries(db).forEach(([key, value]) => {      
                        const anno=db.anno   
                        header.forEach((h,index)=>{
                            //console.log(h.w.toString().trim().toUpperCase(),key.toString().trim().toUpperCase())
                            var v=h.w.toString().trim().toUpperCase()
                            const toCheck=key.toString().trim().toUpperCase()
                            if([v,v.replace(/\s/g,"")].indexOf(toCheck)>-1)
                            arrayRow[index]=value

                            Object.entries(db.mesi).forEach(([mese, valoreMese]) => {  
     
                                if(valoreMese!==null&&[mese].indexOf(v.replace(/\s/g,""))>-1&&header[index].w!=="TOTALE ORE")
                                {
                                    //console.log(valoreMese,valoreMese.length)
                                    const giornilavorativi=valoreMese[valoreMese.length-1]                                    
                                    const totaleoremese=(includiFestivi===true?parseFloat(giornilavorativi.totaleore.replace("NaN","0").replace(",",".")):getTotaleOreSenzaFestivi(parseInt(anno),mese,valoreMese))
                                    const totaleoremeseDC=parseFloat(parseFloat(giornilavorativi.totaleOreDaCampo.replace("NaN","0").replace(",",".")).toFixed(2).toString())

                                    arrayRow[index]=totaleOreDaCampo===true?(isNaN(totaleoremeseDC)?0:totaleoremeseDC):totaleoremese
                                }
                            })
                            if((arrayRow[index]===""||arrayRow[index]==="NaN")&&index<=16)
                            arrayRow[index]=0
                        })
                    })
                    arrayRow.forEach((cell,index)=>{
                            if(cell!==null&&cell.hasOwnProperty('f'))
                            {
                                const regex = /[A-z]{1}4/g ;
                                const found = cell.f.match(regex);
                                var string=cell.f
                                if(found!==null)
                                found.forEach(value=>{
                                    string=string.replace(value,(value[0]+counter.toString()))
                                })
                                arrayRow[index].f=string
                            }
                    })             
                    dataArray.splice(3, 0, arrayRow)
                })
                dataArray.forEach((row,index)=>
                {
                    //console.log(row)
                    if(row!==null&&row.length>0)
                    {
                        if(row[0]!==null)
                        if(row[0].hasOwnProperty("w")&&row[0].w.trim()==="TOTALE")
                        {
                            row.forEach((cell,cellIndex)=>
                            {
                                const letter=XLSX.utils.encode_col(cellIndex)
                                switch(letter)
                                {
                                    case "X":
                                        if(cell.hasOwnProperty('f'))
                                        dataArray[index][cellIndex].f="U"+index+"*100/S"+index
                                    break;
                                    default:
                                        if(cell.hasOwnProperty('f')&&cell.f.indexOf("SUM")>-1)
                                        dataArray[index][cellIndex].f="SUM("+letter+4+":"+letter+(index-1)+")"
                                    break;
                                }
                                //console.log(index, cell,cellIndex,XLSX.utils.encode_col(cellIndex),dataArray.length)
                            })
                        }
                    }
                })
                

                wb.Sheets[wsname]=XLSX.utils.json_to_sheet(dataArray, {skipHeader:true})
                
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                var a = document.createElement("a");
                var file = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                a.href = URL.createObjectURL(file);
                a.download = (new Date()).getTime()+'_proiezione.xlsx';
                a.click();
                NotificationManager.success("Proiezione Creata con successo!")
                
            })
        }
    }
    var creaRegistroPresenze=()=>{
        if(database!==null&&database.length<=0)
        NotificationManager.warning("Nessun Database selezionato oppure è vuoto")
        else{
            fetch('./xlsx/registro_presenze.xlsx')
            .then(response => response.arrayBuffer())
            .then(text => {
                const wb =XLSX.read(text, { bookType: 'xlsx',type: 'binary' })
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                var range = XLSX.utils.decode_range(ws['!ref']);
                var dataArray=[]
                for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {  
                    var dataRow=[]            
                    for(let colNum=range.s.c; colNum <= range.e.c; colNum++)
                    {
                        const cell = ws[XLSX.utils.encode_cell({r: rowNum, c: colNum})];
                        dataRow.push(typeof cell==='undefined'?'':cell); 
                    }
                    dataArray.push(dataRow)                    
                }
                
                const daysOfYear=[]
                const daysOfYearDate=[]

                dataArray[0].forEach(value=>{
                    if(value!=="")
                    {
                        var d=ExcelDateToJSDate(value.v)
                        daysOfYearDate.push(d)
                        daysOfYear.push(d.toLocaleDateString("it"))
                    }
                    else 
                    daysOfYear.push("")
                })

                //console.log(daysOfYear[2])

                database.sort((a,b)=>{return (a.cognome!==null?a.cognome:"").toString().localeCompare(b.cognome!==null?b.cognome:"")}).forEach((db,dbIndex)=>{
                    const anno=db.anno
                    var row=[]
                    if(peopleChecked[db.codicefiscale]===true)
                    {
                        row[0]=[db.cognome,db.nome].join(" ")
                        row[1]=db.codicefiscale
                        Object.keys(db.mesi).forEach((value,index)=>{
                            const mese=value
                            const giorniDelMese=db.mesi[value]
                            if(giorniDelMese!==null)
                            {
                                giorniDelMese.sort((a,b)=>{return a.giorno-b.giorno}).forEach(m=>{
                                    const giorno=parseInt(m.giorno)
                                    if(giorno>0)
                                    {
                                        const mt=getMonthFromString(mese)
                                        const data=[giorno,mt,anno].join("/")
                                        const date=new Date(anno,mt-1,giorno)
                                        
                                        const dataIndex=daysOfYear.indexOf(data)
                                        if(dataIndex>0&&((includiFestivi===false&&(date.getDay()>0))||includiFestivi===true))
                                        {             

                                            const ore=parseFloat(m.ore.replace(",","."))
                                            const inizioM=hoursToValue(oraInizioM)       
                                            const fineM=hoursToValue(oraFineM)  
                                            const inizioP=hoursToValue(oraInizioP)             
                                            const fineP=hoursToValue(oraFineP)           
                                            const pausa=inizioP.sum-fineM.sum
                                            
                                            if(ore>0)
                                            {
                                                row[dataIndex]=inizioM.str
                                                row[dataIndex+1]=(ore>=(fineM.sum-inizioM.sum))?fineM.str:(numToHours(inizioM.sum+ore))
                                                if(ore>(fineM.sum-inizioM.sum)&&inizioP.str!=="00:00"&&fineP.str!=="00:00")
                                                {
                                                    row[dataIndex+2]=inizioP.str
                                                    row[dataIndex+3]=ore>=((fineM.sum-inizioM.sum)+(fineP.sum-inizioP.sum))?fineP.str:numToHours(inizioM.sum+pausa+ore)
                                                }
                                            }
                                            
                                            //console.log({index:dataIndex,ore:ore,inizioM:inizioM,fineM:fineM,inizioP:inizioP,fineP:fineP})
                                        }
                                    }
                                })
                            }
                            //console.log({value:db.mesi[value],index:index,db:db,dbIndex:dbIndex})
                        })
                        dataArray.push(row)
                    }
                })
                dataArray[0]=daysOfYear
                wb.Sheets[wsname]=XLSX.utils.json_to_sheet(dataArray, {skipHeader:true})
                
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                var a = document.createElement("a");
                var file = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                a.href = URL.createObjectURL(file);
                a.download = (new Date()).getTime()+'_registro_presenze.xlsx';
                a.click();
                NotificationManager.success("Registro Presenze Creato con successo!")
                
            })
        }
    }*/

   /* var cambiaOrario=(orario,e)=>{
        switch(orario){
            case "inizio_m": 
            setOraInizioM(e.target.value)
            break
            case "fine_m": 
            setOraFineM(e.target.value)
            break
            case "inizio_p": 
            setOraInizioP(e.target.value)
            break
            case "fine_p": 
            setOraFineP(e.target.value)
            break
            default:
            break
        }
    }*/

    /*var handleIncludiFestivi=(e)=>{
        setIncludiFestivi(e.target.checked)
    }*/

    return (<div>
                <NotificationContainer/>
                <h1>Crea Documenti</h1>
                <hr />
                <div>
                    <div className='block-inline'>
                    <FileUploader hoverTitle="Trascina qui" label="Clicca o Trascina qui il database in:" handleChange={readJson} name="file" types={fileTypes} />
                    </div>
                </div>
                <div className='block-inline'>
                    <div className="creation_box">
                        <h3>Crea Report</h3>
                        <div>
                        </div>
                        <button className='btn' onClick={creaReportBustePaghe}>Crea</button>
                    </div>
                </div>         
            </div>)
}

export default DocumentCreator;
/*
<div className="creation_box">
<h3>Crea Proiezione</h3>
<div>
<input type="checkbox" id="iFP" onChange={handleIncludiFestivi} checked={includiFestivi} /> <label htmlFor="iFP" >Includi Domeniche</label>
</div>
<div>
<input type="checkbox" id="totalOreDaCampo" onChange={()=>setTotaleOreDaCampo(!totaleOreDaCampo)} checked={totaleOreDaCampo} /> <label htmlFor="totalOreDaCampo" >Totale delle ore da campo?</label>
</div>
<button className='btn' onClick={creaProiezione}>Crea</button>
</div>
<div className="creation_box">
<h3>Crea Registro Presenze</h3>
<div>
<input type="checkbox" id="iF" onChange={handleIncludiFestivi} checked={includiFestivi} /> <label htmlFor="iF" >Includi Domeniche</label>
</div>
<div>
    Inizio e Fine Mattina<br />
    <input type="time" className="inizio_m" value={oraInizioM} onChange={cambiaOrario.bind(this,"inizio_m")} />
    <input type="time" className="fine_m" value={oraFineM} onChange={cambiaOrario.bind(this,"fine_m")} />
</div>
<div>
    Inizio e Fine Pomeriggio<br />
    <input type="time" className="inizio_p" value={oraInizioP} onChange={cambiaOrario.bind(this,"inizio_p")} />
    <input type="time" className="fine_p" value={oraFineP} onChange={cambiaOrario.bind(this,"fine_p")} />
</div>
<button className='btn' onClick={creaRegistroPresenze}>Crea</button>
<div>
    {(database.length>0)?<label style={databaseStyle} htmlFor="selezionatutti"><input id="selezionatutti" type="checkbox" onChange={(event) => selezionaTutti(event.target.checked)} checked={checkedAll} />Seleziona Tutti</label>:""}
    {databaseItems}
</div>
</div>
*/
