import './App.scss'
import 'boxicons/css/boxicons.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import Datafinder from './Datafinder';
import Pdftoexcel from './Pdftoexcel';
import Dataverify from './Dataverify';
import Dashboard from './Dashboard';
import DocumentCreator from './DocumentCreator';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<AppLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path='/leggibustepaghe' element={<Datafinder />} />
                    <Route path='/pdftoexcel' element={<Pdftoexcel />} />
                    <Route path='/verificadati' element={<Dataverify />} />
                    <Route path='/creadocumenti' element={<DocumentCreator />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;