import { Link } from 'react-router-dom';

function Dashboard() {

  return (
    <div className="dashboard_buttons">   
        <h1>Dashboard PDF Data Entring</h1>  
        <hr />  
        <Link to={"/leggibustepaghe"}><i className='bx bx-file-find'></i><span>Leggi BP PDF</span></Link>
        <Link to={"/verificadati"}><i className='bx bx-check-double'></i><span>Verifica Dati</span></Link>
        <Link to={"/creadocumenti"}><i className='bx bx-file'></i><span>Crea Documenti</span></Link>
    </div>
  );
}

export default Dashboard;